import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { RouterOutlet } from '@angular/router';
import {NavigationComponent} from "./components/navigation/navigation.component";
import {CommonModule} from "@angular/common";
import {FooterComponent} from "./components/footer/footer.component";
import {CookieConsentComponent} from "./components/cookie-consent/cookie-consent.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavigationComponent, CommonModule, FooterComponent, CookieConsentComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'e2t-webpage';

  isBookingConfirmedPage= false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isBookingConfirmedPage = this.router.url.includes('booking-confirmed');
      }
    });
  }
}
