import {Component, OnDestroy} from '@angular/core';
import {HttpClientModule, HttpClient, HttpHeaders} from "@angular/common/http";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {InputTextareaModule} from "primeng/inputtextarea";
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {CheckboxModule} from "primeng/checkbox";
import {TranslateModule} from "@ngx-translate/core";
import {CardModule} from "primeng/card";
import {Subscription} from 'rxjs';
import {tap, catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    TranslateModule,
    CheckboxModule,
    HttpClientModule,
    ToastModule
  ],
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  providers: [MessageService]
})

export class ContactComponent implements OnDestroy {
  contactForm: FormGroup;
  consentLabel: string = '';
  buttonText: string = '';
  buttonIcon: string = 'pi pi-chevron-down';
  private langChangeSubscription: Subscription;
  private apiUrl = environment.apiUrl;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private http: HttpClient,
    private messageService: MessageService
  ) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      organization: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(/^\+?[\d\s-]+$/)]],
      questionText: ['', Validators.required],
      consent: [false, Validators.requiredTrue]
    });
    this.initTranslations();

    this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.initTranslations();
    });
  }

  initTranslations() {
    this.translate.get('contact.form.consent_short').subscribe((res: string) => this.consentLabel = res);
    this.translate.get('button.down').subscribe((res: string) => this.buttonText = res);
  }

  toggleConsentText() {
    if (this.consentLabel === this.translate.instant('contact.form.consent_short')) {
      this.translate.get('contact.form.consent').subscribe((res: string) => this.consentLabel = res);
      this.translate.get('button.up').subscribe((res: string) => this.buttonText = res);
      this.buttonIcon = 'pi pi-chevron-up';
    } else {
      this.translate.get('contact.form.consent_short').subscribe((res: string) => this.consentLabel = res);
      this.translate.get('button.down').subscribe((res: string) => this.buttonText = res);
      this.buttonIcon = 'pi pi-chevron-down';
    }
  }

  sendFormData(formData: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(this.apiUrl, formData, {headers});
  }

  onSubmit() {
    if (this.contactForm.valid) {
      const formData = {
        message: `<html><body><h2>email: </h2>${this.contactForm.value.email}<br>
                              <h2>name: </h2>${this.contactForm.value.name}<br>
                              <h2>organization: </h2>${this.contactForm.value.organization}<br>
                              <h2>phone: </h2>${this.contactForm.value.phone}<br>
                              <h2>questionText: </h2>${this.contactForm.value.questionText}<br>
                              <h2>consent: </h2>${this.contactForm.value.consent.toString()}<br></body></html>`,
        subject: `Message from WWW page`
      };

      console.log('Form data to be sent:', formData);

      this.sendFormData(formData).pipe(
        tap(response => {
          console.log('Email sent successfully', response);
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('contact.form.success_message_summary'),
            detail: this.translate.instant('contact.form.success_message_detail'),
            life: 10000
          });
        }),
        catchError(error => {
          console.error('Error sending email', error);
          return of(null);
        })
      ).subscribe();
    } else {
      console.log('Form is invalid');
      this.contactForm.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
  navigateToLink() {
    window.location.href = 'https://meetings-eu1.hubspot.com/bartosz-nowak';
  }
}
