<ng-container *ngIf="!isBookingConfirmedPage" >
  <app-navigation></app-navigation>

  <app-cookie-consent></app-cookie-consent>
</ng-container>

<router-outlet></router-outlet>

<ng-container *ngIf="!isBookingConfirmedPage" >
  <app-footer></app-footer>
</ng-container>
