import { NgModule } from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProductsComponent } from './components/products/products.component';
import { PartnersComponent } from './components/partners/partners.component';
import { ContactComponent } from './components/contact/contact.component';
import { HowToUseComponent } from './components/how-to-use/how-to-use.component';
import { EnterpriseComponent } from './components/enterprise/enterprise.component';
import {BookingConfirmedComponent} from "./components/booking-confirmed/booking-confirmed.component";

export const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  // { path: 'products', component: ProductsComponent },
  // { path: 'partners', component: PartnersComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'how-to-use', component: HowToUseComponent },
  // { path: 'enterprise', component: EnterpriseComponent },
  { path: 'booking-confirmed', component: BookingConfirmedComponent },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
