<main class="contacts">
  <p-toast class="custom__success-message custom__error-message"></p-toast>
  <section class="form-section">
    <div class="form-section__title-container">
      <h2 class="contacts__title">{{ 'contact.title' | translate }}</h2>
      <h3 class="contacts__subtitle" [innerHTML]="'contact.title2' | translate"></h3>
    </div>
    <form [formGroup]="contactForm" class="form-section__form card flex justify-content-center" (ngSubmit)="onSubmit()">
      <p class="form-section__paragraph" [innerHTML]="'contact.form.title' | translate"></p>
      <div class="form-section__row">
        <div class="form-section__group form-section__group--half">
          <label for="name">{{ 'contact.form.name' | translate }}</label>
          <input pInputText type="text" id="name" formControlName="name" required
                 placeholder="{{'contact_placeholder.form.name_title' | translate}}" class="form-section__group-input"/>
          <div *ngIf="contactForm.get('name')?.invalid && contactForm.get('name')?.touched" class="form-section__error">
            {{ 'contact.form.name_error' | translate }}
          </div>
        </div>
        <div class="form-section__group form-section__group--half">
          <label for="organization">{{ 'contact.form.company' | translate }}</label>
          <input pInputText type="text" id="organization" formControlName="organization" required
                 placeholder="{{'contact_placeholder.form.company_title' | translate}}"
                 class="form-section__group-input"/>
          <div *ngIf="contactForm.get('organization')?.invalid && contactForm.get('organization')?.touched"
               class="form-section__error">
            {{ 'contact.form.company_error' | translate }}
          </div>
        </div>
      </div>
      <div class="form-section__row">
        <div class="form-section__group form-section__group--half">
          <label for="email">{{ 'contact.form.email' | translate }}</label>
          <input pInputText type="email" id="email" formControlName="email" required
                 placeholder="{{'contact_placeholder.form.email_title' | translate}}"
                 class="form-section__group-input"/>
          <div *ngIf="contactForm.get('email')?.invalid && contactForm.get('email')?.touched"
               class="form-section__error">
            {{ 'contact.form.email_error' | translate }}
          </div>
        </div>
        <div class="form-section__group form-section__group--half">
          <label for="phone">{{ 'contact.form.phone' | translate }}</label>
          <input pInputText type="tel" id="phone" formControlName="phone" required
                 placeholder="{{'contact_placeholder.form.phone_title' | translate}}"
                 class="form-section__group-input"/>
          <div *ngIf="contactForm.get('phone')?.invalid && contactForm.get('phone')?.touched"
               class="form-section__error">
            {{ 'contact.form.phone_error' | translate }}
          </div>
        </div>
      </div>
      <div class="form-section__group">
        <label for="text">{{ 'contact.form.text' | translate }}</label>
        <textarea pInputTextarea id="text" rows="1" formControlName="questionText" autoResize="true" required
                  placeholder="{{'contact_placeholder.form.text_title' | translate}}"
                  class="form-section__group-input"></textarea>
        <div *ngIf="contactForm.get('questionText')?.invalid && contactForm.get('questionText')?.touched"
             class="form-section__error">
          {{ 'contact.form.text_error' | translate }}
        </div>
      </div>
      <div class="form-section__group form-section__group--consent">
        <div class="form-section__consent" id="rodo">
          <div class="p-field-checkbox">
            <p-checkbox formControlName="consent" [binary]="true" inputId="consent-checkbox" id="box"></p-checkbox>
            <div class="form-section__consent-text">
              <span>{{ consentLabel }}</span>
            </div>
          </div>
        </div>
        <div class="form-section__consent-button">
          <button pButton type="button" icon="{{buttonIcon}}" label="{{buttonText}}" (click)="toggleConsentText()"
                  class="form-section__toggle-button"></button>
        </div>
        <div *ngIf="contactForm.get('consent')?.invalid && contactForm.get('consent')?.touched"
             class="form-section__error form-section__error-consent">
          {{ 'contact.form.consent_error' | translate }}
        </div>
      </div>
      <button pButton type="submit" label="{{'contact.form.submit' | translate}}" class="form-section__submit-button"
              [disabled]="!contactForm.valid"></button>
    </form>
  </section>
  <section class="contacts-meeting">
    <div class="contacts-meeting__image-wrapper">
      <img class="contacts-meeting__image" src="/assets/icons/contacts-calendar.svg" alt="calendar picture">
    </div>
    <div class="contacts__text-container contacts-meeting__text-container">
      <h2 class="contacts-meeting__title contacts__title">{{ 'contact_meeting.title' | translate }}</h2>
      <div class="contacts-meeting__paragraph-wrapper">
        <p class="contacts__paragraph">{{ 'contact_meeting.paragraph_1' | translate }}</p>
        <p class="contacts__paragraph">{{ 'contact_meeting.paragraph_2' | translate }}</p>
        <p class="contacts__paragraph">{{ 'contact_meeting.paragraph_3' | translate }}</p>
      </div>
        <button pButton type="submit" label="{{'contact_meeting.link_text' | translate}}" class="form-section__submit-button"
                (click)="navigateToLink()"> </button>
    </div>
  </section>
  <section class="contact-info">
    <div class="contact-info__title">
      <h1 class="contacts__title">{{ 'contact_card.info_title' | translate }}</h1>
    </div>
    <div class="contact-info__cards card flex justify-content-center">
      <p-card class="contact-info__card">
        <ng-template pTemplate="header">
          <img alt="Card" src="/assets/icons/icon_racoon_contact.svg" class="contact-info__card-image"/>
        </ng-template>
        <div class="contact-info__card-text">
          <h2>{{ 'contact_card.first_card_title' | translate }}</h2>
          <p class="contact-info__card-paragraph" [innerHTML]="'contact_card.first_card_desc' | translate"></p>
          <ul class="contact-info__card-list">
            <li
              class="contact-info__card-item contact-info__card-item-title">{{ 'contact_card.first_card_list_item1' | translate }}
            </li>
            <li class="contact-info__card-item contact-info__card-item-link"><i class="pi pi-phone"></i>
              <a href="tel:+48 530 947 564"
                 class="contact-info__card-link">+48 530 947 564</a></li>
            <li
              class="contact-info__card-item contact-info__card-item-title">{{ 'contact_card.first_card_list_item2' | translate }}
            </li>
            <li class="contact-info__card-item contact-info__card-item-link"><i class="pi pi-envelope"></i>
              <a href="mailto:info@email2tms.com"
                 class="contact-info__card-link">info&#64;email2tms.com</a></li>
            <li class="contact-info__card-item contact-info__card-item-title"
                [innerHTML]="'contact_card.first_card_list_item3' | translate"></li>
          </ul>
        </div>
      </p-card>
      <p-card class="contact-info__card">
        <ng-template pTemplate="header">
          <img alt="Card" src="/assets/icons/icon_data_company.svg" class="contact-info__card-image"/>
        </ng-template>
        <div class="contact-info__card-text">
          <h2>{{ 'contact_card.second_card_title' | translate }}</h2>
          <p class="contact-info__card-paragraph" [innerHTML]="'contact_card.second_card_desc' | translate"></p>
          <p class="contact-info__card-paragraph--address"
             [innerHTML]="'contact_card.second_card_address' | translate"></p>
          <span class="contact-info__card-data" [innerHTML]="'contact_card.second_card_data' | translate"></span>
        </div>
      </p-card>
    </div>
  </section>
  <section class="about-us">
    <article class="about-us__container">
      <div class="about-us__content">
        <div class="about-us__title">
          <h2 class="contacts__title">{{ 'about_us.title' | translate }}</h2>
          <h2 class="contacts__subtitle">{{ 'about_us.subtitle' | translate }}</h2>
        </div>
        <div class="contacts__text-container about-us__text">
          <p class="contacts__paragraph" [innerHTML]="'about_us.first_paragraph' | translate"></p>
          <p class="contacts__paragraph" [innerHTML]="'about_us.second_paragraph' | translate"></p>
        </div>
      </div>
      <div class="about-us__image-container"><img src="assets/icons/about-us-img.png" alt="photo our team"
                                                  class="about-us__image">
      </div>
    </article>
  </section>
  <section class="social-media">
    <div class="social-media__container">
      <div class="social-media__text">
        <h2 >{{ 'social_media.title' | translate }}</h2>
        <p class="social-media__paragraph" [innerHTML]="'social_media.description' | translate"></p>
      </div>
      <div class="social-media__icons">
        <div class="social-media__icon-container1">
          <a href="https://www.linkedin.com/company/email2tms/" class="social-media__icon">
          <img src="assets/icons/linkedin_icon.svg" alt="LinkedIn" class="social-media__icon-image">
          <p>{{ 'social_media.linkedin' | translate }}</p>
        </a></div>
        <div class="social-media__icon-container1">
          <a href="https://www.facebook.com/Email2TMS/" class="social-media__icon s-m-img1">
          <img src="assets/icons/facebook_icon.svg" alt="Facebook" class="social-media__icon-image">
          <p>{{ 'social_media.facebook' | translate }}</p>
        </a></div>
      </div>
    </div>
  </section>
</main>
